import React from "react";
import { businessEntityStyle } from "@aim-mf/styleguide";

class HeaderRender extends React.Component {
  render() {
    return (
      <span style={businessEntityStyle.headerCellStyle}>
        {this.props.title}
      </span>
    );
  }
}

export { HeaderRender };
