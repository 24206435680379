import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy, process } from "@progress/kendo-data-query";
import {
  IconButtonSolid,
  SearchBar,
  Pill,
  FormatDate,
  IconSVG,
  mapping,
  businessEntityStyle,
} from "@aim-mf/styleguide";
import { HeaderRender } from "../HeaderRender";

class EntityRegistry extends React.Component {
  constructor(props) {
    super(props);
    let dataWithSelection = this.props.data.map((dataInfo) => {
      return { selected: false, ...dataInfo };
    });
    this.state = {
      sort: [{ field: "display_name", dir: "asc", data: this.props.data }],
      result: dataWithSelection,
      filterData: dataWithSelection,
      searchData: dataWithSelection,
      dataState: "",
      editUser: { display: false },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let dataWithSelection = nextProps.data.map((dataInfo) => {
        return { selected: false, ...dataInfo };
      });
      this.setState({
        sort: [{ field: "username", dir: "asc", data: nextProps.data }],
        result: dataWithSelection,
        filterData: dataWithSelection,
        searchData: dataWithSelection,
        dataState: "",
      });
    }
  }

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    let displayResult = JSON.parse(JSON.stringify(this.state.result));
    displayResult = displayResult.map((a) => {
      let created_date = FormatDate(a.created_date, "MM/DD/YYYY HH:MM:SS");
      return {
        ...a,
        created_date: created_date,
      };
    });
    return (
      <div style={businessEntityStyle.entityTableWrapper}>
        <div
          style={
            this.state.editUser.display
              ? businessEntityStyle.BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div style={businessEntityStyle.tableTitlePadding}>
            <div style={businessEntityStyle.titleStyle}>Entity Registry</div>
            <div className="risk-card-subtitle">
              This manages and register the entities
            </div>
          </div>
          <div
            id="table-tool-bar"
            style={businessEntityStyle.entityToolBarWrapper}
            className="mb-1"
          >
            <div className="mx-1">
              <SearchBar
                searchCallBack={this.onSearchCallBack}
                hint={"Search by user name..."}
              />
            </div>
            {this.props.access_level <= 1 && (
              <div className="mx-1">
                <IconButtonSolid
                  height={"2rem"}
                  width={"fit-content"}
                  color={"Danger"}
                  name={"Archive"}
                  iconPos={"Left"}
                  icon={
                    <IconSVG name={"archive"} size={0.8} color={"#ffffff"} />
                  }
                  clickEvent={() => {}}
                />
              </div>
            )}
            {this.props.access_level <= 1 && (
              <div className="mx-1">
                <IconButtonSolid
                  height={"2rem"}
                  width={"fit-content"}
                  iconPos={"Left"}
                  icon={
                    <IconSVG name={"newRisk"} size={0.8} color={"#ffffff"} />
                  }
                  name={"Add New Entity"}
                  clickEvent={this.props.handleCreateEntityNav}
                />
              </div>
            )}
          </div>

          <Grid
            style={businessEntityStyle.gridStyle}
            {...this.state.dataState}
            data={this.customOrderBy(displayResult, this.state.sort)}
            sortable
            onDataStateChange={this.dataStateChange}
            selectedField="selected"
            onSelectionChange={this.selectionChange}
            onHeaderSelectionChange={this.headerSelectionChange}
            onRowClick={this.rowClick}
            sort={this.state.sort}
            rowRender={this.rowRender}
            onSortChange={(e) => {
              let sortConfig = e.sort[0];
              if (!sortConfig) {
                this.setState({
                  sort: [{ data: this.props.data }],
                });
              } else {
                sortConfig = { ...sortConfig, data: this.props.data };
                this.setState({
                  sort: [sortConfig],
                });
              }
            }}
          >
            <Column
              field="selected"
              width={(50 / 16) * rem + "px"}
              headerSelectionValue={
                this.state.result.findIndex(
                  (dataItem) => dataItem.selected === false
                ) === -1
              }
            />
            <Column
              field="entity_number"
              title="ID"
              width={(170 / 16) * rem + "px"}
              cell={(props) => {
                return (
                  <td className="table-risk-id">
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div
                      className="table-risk-id div_hover"
                      onClick={() => {
                        this.props.handleEntityDetail(props.dataItem);
                      }}
                    >
                      {props.dataItem["is_child"] ? "⤷" : " "}
                      {props.dataItem[props.field]}
                    </div>
                  </td>
                );
              }}
              headerCell={HeaderRender}
            />
            <Column
              field="display_name"
              title="Entity Name"
              width={(370 / 16) * rem + "px"}
              headerCell={HeaderRender}
            />
            <Column
              field="risks_count"
              title="Number of RIsk"
              width={(220 / 16) * rem + "px"}
              headerCell={HeaderRender}
            />
            <Column
              field="created_date"
              title="Date Created"
              width={(200 / 16) * rem + "px"}
              headerCell={HeaderRender}
            />
            <Column
              field="owner"
              title="Administrator"
              cell={(props) => {
                if (props.dataItem[props.field]) {
                  return (
                    <td>
                      {props.dataItem[props.field].map((owner) => {
                        return (
                          <Pill
                            key={owner}
                            pillStyle={{
                              display: "inline-flex",
                              marginRight: "0.3rem",
                            }}
                            content={owner}
                            color={"rgba(124,199,255,0.2)"}
                          />
                        );
                      })}
                    </td>
                  );
                } else {
                  return <td />;
                }
              }}
              headerCell={HeaderRender}
            />
            <Column
              title="Edit"
              width={(60 / 16) * rem + "px"}
              cell={(props) => {
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <td
                    onClick={() => {
                      this.handleEditUser(props.dataItem);
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        placeContent: "center",
                        width: "100%",
                      }}
                    >
                      <IconSVG
                        name={"edit"}
                        color={mapping["Color_Basic_Primary"]}
                      />
                    </div>
                  </td>
                );
              }}
              headerCell={HeaderRender}
            />
            <Column
              title="Delete"
              width={(70 / 16) * rem + "px"}
              cell={(props) => {
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <td
                    onClick={() => {
                      let payload = JSON.parse(JSON.stringify(props.dataItem));
                      payload.is_active = false;
                      delete payload.email;
                      this.props.handleUpdateUser(payload, [], []);
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        placeContent: "center",
                        width: "100%",
                      }}
                    >
                      <IconSVG
                        name={"delete"}
                        color={mapping["Color_Basic_Primary"]}
                      />
                    </div>
                  </td>
                );
              }}
              headerCell={HeaderRender}
            />
          </Grid>
        </div>
      </div>
    );
  }

  selectionChange = (event) => {
    const result = this.state.result.map((item) => {
      if (item.username === event.dataItem.username) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    this.setState({ result });
  };
  rowClick = (event) => {
    // console.log(event)
    this.props.handleEntityDetail(event.dataItem);
    //console.log(this.props.userList);
    // let last = this.lastSelectedIndex;
    // const result = [...this.state.result];
    // const current = result.findIndex((dataItem) => dataItem === event.dataItem);
    // result.forEach((item) => (item.selected = false));
    // const select = !event.dataItem.selected;
    // for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
    //   result[i].selected = select;
    // }
    // this.setState({ result });
  };

  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const result = this.state.result.map((item) => {
      item.selected = checked;
      return item;
    });
    this.setState({ result });
  };

  // search related
  onSearchCallBack = (e) => {
    let searchContent = e.target.value;

    let resultData = this.state.filterData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let searchData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );

    if (!searchContent) {
      this.setState({ result: resultData, searchData: searchData });
      return;
    }
    // find the user that suits the search keywords (by display name)
    let userOnSearch = this.state.filterData.filter((userItem) => {
      if (userItem.display_name) {
        return userItem.display_name
          .toLowerCase()
          .includes(searchContent.toLowerCase());
      }
    });
    this.setState({ searchData: userOnSearch, result: userOnSearch });
  };

  // filter related
  createDataState = (dataState) => {
    let resultData = this.state.searchData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let filterData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    return {
      result: process(resultData, dataState).data,
      filterData: process(filterData, dataState).data,
      dataState: dataState,
    };
  };

  dataStateChange = (event) => {
    var datastate = event.data;
    datastate.take = 100;
    this.setState(this.createDataState(datastate));
  };

  // sorting function
  customOrderBy = (data, sort) => {
    let result = [];
    let sortedData = orderBy(data, sort);

    if (sortedData.length === 1) {
      return sortedData;
    }

    let parentEntity = sortedData.filter((a) => !a.is_child);
    parentEntity.map((entity) => {
      result = result.concat(entity);
      let childIDList = Object.keys(entity.children);
      let subEntity = sortedData.filter((subentity) => {
        return childIDList.indexOf(subentity.ou_id) !== -1;
      });
      subEntity.map((sub) => {
        result = result.concat(sub);
      });
    });
    // while (sortedData.length !== 0) {
    //   if (!sortedData[0].is_child) {
    //     result = result.concat(sortedData[0]);
    //     sortedData = sortedData.filter((entity) => {
    //       return entity.ou_id !== sortedData[0].ou_id;
    //     });
    //
    //     let childIDList = Object.keys(sortedData[0].children)
    //     let subEntity = sortedData.filter((entity) => {
    //       return childIDList.indexOf(entity.ou_id) !== -1;
    //     });
    //     result = result.concat(subEntity);
    //     sortedData = sortedData.filter((entity) => {
    //       return childIDList.indexOf(entity.ou_id) === -1;
    //     });
    //   }
    // }
    return result;
  };

  //archive function
  archiveUser = () => {
    // get all risk with selected
    let selectedUser = this.state.result.filter((user) => {
      return user.selected;
    });
    let archiveUserID = selectedUser.map((a) => a.username);
    this.props.archive(archiveUserID);
  };

  rowRender(trElement, props) {
    const parentRow = { backgroundColor: "#00000066" };
    const subRiskRow = { backgroundColor: "#00000000" };
    const trProps = {
      style: props.dataItem.is_child ? subRiskRow : parentRow,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }
}

export { EntityRegistry };
