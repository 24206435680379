import React from "react";
import { mapping, Tab_BookMark, businessEntityStyle } from "@aim-mf/styleguide";
import { Information } from "./content/infomation";
import { RiskRegistry } from "./content/riskRegistry";
import { AggregationSetting } from "./content/aggregationSetting";

const TabList = ["Entity Information", "Entity Risk Registry"];

class ViewContentSwitchTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Tab: TabList[0],
    };
  }

  render() {
    if (!this.props.OU) {
      return <div />;
    }
    if (
      !this.props.OU.is_child &&
      TabList.indexOf("Risk Aggregation Settings") === -1
    ) {
      TabList.push("Risk Aggregation Settings");
    }
    return (
      <div>
        <div style={businessEntityStyle.RiskBasicInfoStyle}>
          <div style={mapping["heading/h3/lightleft"]}>Entity View</div>
          <div style={{ height: "2.5rem" }} />
          <div style={{ display: "inline-flex" }}>
            <Tab_BookMark
              OnTabChange={this.handleTabChange}
              padding={"1.125rem 1.5rem 1.125rem 1.5rem"}
              tabWidth={"fit-content"}
              height={"fit-content"}
              labels={TabList}
              activeBackgroundColor={mapping["Color_BG_05_solid"]}
            />
          </div>
        </div>
        {this.state.Tab === "Entity Information" && (
          <Information
            defaultShareholding={this.props.defaultShareholding}
            OU={this.props.OU}
            userList={this.props.userList}
            OUList={this.props.OUList}
            handleEntityEdit={this.props.handleEntityEdit}
          />
        )}

        {this.state.Tab === "Entity Risk Registry" && (
          <RiskRegistry
            data={this.props.RiskList.filter((risk) => {
              return risk.ou_id === this.props.OU.ou_id;
            })}
          />
        )}

        {this.state.Tab === "Risk Aggregation Settings" && (
          <AggregationSetting
            OU={this.props.OU}
            OUList={this.props.OUList}
            totalAmount={this.props.totalAmount}
            handleOwnership={this.props.handleOwnership}
          />
        )}
      </div>
    );
  }
  handleTabChange = (tabLabel) => {
    this.setState({ Tab: tabLabel });
  };
}

export { ViewContentSwitchTab };
