import React, { useCallback } from "react";
import { Routing } from "./page/routing";
import {
  fetchAdminAPIWithPermission,
  fetchAPIWithPermission,
} from "@aim-mf/api";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";
import { useHistory } from "react-router-dom";

import "./app.scss";
import "bootstrap/dist/css/bootstrap.css";

let fakeTotalAmount = 100000;
let defaultShareholding = 0.0001;
let parentRevenue = 1030904;
let subRevenue = 325812;
const initialState = {
  loadingRisk: true,
  loadingUserList: true,
  loadingOUList: true,
  userList: [],
  riskList: [],
  OUList: [],
  ou_id: JSON.parse(sessionStorage.getItem("CurrentUser")).current_ou,
  pageNav: window.location.href.toString().split(window.location.host)[1],
  currentUser: {},
  OUCreationMsg: "",
  updatingOU: false,
  creatingOU: false,
  updatingShare: false,
};

function App(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    loadingRisk,
    loadingUserList,
    loadingOUList,
    userList,
    riskList,
    OUList,
    ou_id,
    pageNav,
    currentUser,
    OUCreationMsg,
    updatingOU,
    creatingOU,
    updatingShare,
  } = state;

  const history = useHistory();
  React.useEffect(() => {
    dispatch({ type: "changePageNav", data: location.pathname });
    dispatch({
      type: "loadCurrentUserInfo",
      data: {
        ...JSON.parse(sessionStorage.getItem("CurrentUser")),
        ...JSON.parse(sessionStorage.getItem("MyProfile")),
      },
    });

    if (loadingOUList) {
      //console.log("load ou list");
      const subscription = fetchAdminAPIWithPermission(
        "auth/ou/list"
      ).subscribe(
        (results) => {
          ////console.log("in post api results");
          dispatch({ type: "loadOUList", data: results.data });
          dispatch({ type: "setOUID", data: results.data[0].ou_id });
        },
        (err) => {
          dispatch({ type: "notLoadingOUList" });
          console.log(err); // eslint-disable-line
          console.log("business entity reload ou list"); // eslint-disable-line
          // //console.log(err.response.data); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleOUListRequest();
          });
        }
      );
      return () => subscription.unsubscribe();
    }

    if (loadingUserList) {
      //console.log("load userList");
      const subscription = fetchAdminAPIWithPermission(
        "auth/user/list"
      ).subscribe(
        (results) => {
          ////console.log("in post api results");
          dispatch({ type: "loadUserList", data: results.data });
          loadRiskFunction(results.data);
        },
        (err) => {
          dispatch({ type: "notLoadingUserList" });
          //console.log(err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleUserListRequest();
          });
          // //console.log(err.response.data); // eslint-disable-line
        }
      );

      return () => subscription.unsubscribe();
    }

    history.listen((location) => {
      ////console.log(location.pathname);
      dispatch({ type: "changePageNav", data: location.pathname });
    });
  }, [
    loadingRisk,
    loadingUserList,
    loadingOUList,
    history,
    ou_id,
    userList,
    loadRiskFunction,
  ]);

  const loadRiskFunction = useCallback((UserList) => {
    let param = { data: { isArchived: "NON-ARCHIVED" } };
    dispatch({ type: "loadingRisk" });
    const subscription = fetchAPIWithPermission("getrisks", param).subscribe(
      (results) => {
        let newResults = { results: results, userList: UserList };
        dispatch({ type: "newRisk", newResults });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadRiskFunction();
        });
      }
    );

    return () => subscription.unsubscribe();
  });
  const handleOUListRequest = () => {
    dispatch({ type: "loadingOUList" });
  };

  const handleUserListRequest = () => {
    dispatch({ type: "loadingUserList" });
  };

  const handleCreateEntity = (dataPack) => {
    dispatch({ type: "creatingOU" });
    let OU_detail = dataPack.detail;
    let OU_ownership = dataPack.ownership;
    // create OU
    const subscription = fetchAdminAPIWithPermission("auth/ou/create", {
      data: OU_detail,
    }).subscribe(
      (results) => {
        if (OU_detail.is_child) {
          let originalOUListID = OUList.map((a) => a.ou_id);
          let newOUID = results.data.filter((ou) => {
            return originalOUListID.indexOf(ou.ou_id) === -1;
          })[0].ou_id;
          handleOwnership({ child_ou_id: newOUID, ...OU_ownership });
        } else {
          dispatch({ type: "creatingOUFinish" });
          reloadOUList();
        }
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleCreateEntity(dataPack);
        });
        dispatch({ type: "creatingOUFinish" });
        dispatch({ type: "OUCreationMsg", data: err.response.data.detail });
        // //console.log(err.response.data); // eslint-disable-line
      }
    );
    return () => subscription.unsubscribe();
  };

  const handleOwnership = (dataPack) => {
    dispatch({ type: "updatingShare" });
    // create OU
    const subscription = fetchAdminAPIWithPermission("auth/ou/ownership", {
      data: { ...dataPack, ownership: 0 },
    }).subscribe(
      (results) => {
        const setSubscription = fetchAdminAPIWithPermission(
          "auth/ou/ownership",
          {
            data: dataPack,
          }
        ).subscribe(
          (results) => {
            dispatch({ type: "updatingOUFinish" });
            dispatch({ type: "creatingOUFinish" });
            reloadOUList();
            dispatch({
              type: "OUCreationMsg",
              data: "Business unit created successfully",
            });
            dispatch({ type: "updatingShareFinish" });
          },
          (err) => {
            //console.log(err); // eslint-disable-line
            AuthCheckAndAutofix(err, () => {
              handleOwnership(dataPack);
            });
            dispatch({ type: "updatingOUFinish" });
            dispatch({ type: "creatingOUFinish" });
            dispatch({ type: "updatingShareFinish" });
            dispatch({ type: "OUCreationMsg", data: err.response.data.detail });
            // //console.log(err.response.data); // eslint-disable-line
          }
        );
        return () => setSubscription.unsubscribe();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleOwnership(dataPack);
        });
        dispatch({ type: "updatingOUFinish" });
        dispatch({ type: "creatingOUFinish" });
        dispatch({ type: "updatingShareFinish" });
        dispatch({ type: "OUCreationMsg", data: err.response.data.detail });
        // //console.log(err.response.data); // eslint-disable-line
      }
    );
    return () => subscription.unsubscribe();
  };

  const handleOUCompleteConfirm = () => {
    dispatch({ type: "OUCreationMsg" });
  };

  const handleEntityEdit = (dataPack) => {
    dispatch({ type: "updatingOU" });
    let OU_detail = dataPack.detail;
    let OU_ownership = dataPack.ownership;
    let OU_PreOwnership = dataPack.PreOwnership;
    // edit OU
    const subscription = fetchAdminAPIWithPermission("auth/ou/edit", {
      data: OU_detail,
    }).subscribe(
      (results) => {
        // edit detail success, try to change ownership (if the parent change?)
        if (OU_PreOwnership.parent_ou_id[0] !== OU_ownership.parent_ou_id[0]) {
          let childID = OU_detail.ou_id;
          let newParentID = OU_ownership.parent_ou_id[0];
          let PreParentID = OU_PreOwnership.parent_ou_id[0];
          // remove older ownership by setting the ownership to 0
          handleOwnership({
            child_ou_id: childID,
            parent_ou_id: PreParentID,
            ownership: 0,
          });
          // add new ownership
          handleOwnership({
            child_ou_id: childID,
            parent_ou_id: newParentID,
            ownership: 0.00001,
          });
        } else {
          dispatch({ type: "updatingOUFinish" });
          reloadOUList();
        }
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleEntityEdit(dataPack);
        });
        dispatch({ type: "updatingOUFinish" });
        dispatch({ type: "OUCreationMsg", data: err.response.data.detail });
        // //console.log(err.response.data); // eslint-disable-line
      }
    );
    return () => subscription.unsubscribe();
  };

  const reloadOUList = React.useCallback((id) => {
    dispatch({ type: "loadingOUList" });
    // dispatch({ type: "loadingParentRiskTier" });
  });

  return (
    <div>
      <Routing
        loading={
          loadingRisk ||
          loadingOUList ||
          loadingUserList ||
          creatingOU ||
          updatingOU ||
          updatingShare
        }
        access_level={
          JSON.parse(window.sessionStorage.getItem("MyProfile")).access_level
        }
        current_ou={
          JSON.parse(window.sessionStorage.getItem("CurrentUser")).current_ou
        }
        OUCreationMsg={OUCreationMsg}
        totalAmount={fakeTotalAmount}
        pageNav={pageNav}
        userList={userList}
        RiskList={riskList}
        OUList={OUList}
        currentUser={currentUser}
        defaultShareholding={defaultShareholding}
        handleUserListRequest={handleUserListRequest}
        handleCreateEntity={handleCreateEntity}
        handleEntityEdit={handleEntityEdit}
        handleOwnership={handleOwnership}
      />
    </div>
  );

  function reducer(state = initialState, action) {
    switch (action.type) {
      case "loadingRisk":
        return { ...state, loadingRisk: true };
      case "loadCurrentUserInfo":
        return {
          ...state,
          currentUser: action.data,
        };
      case "changePageNav":
        return { ...state, pageNav: action.data };
      case "loadingUserList":
        return { ...state, loadingUserList: true };
      case "loadingOUList":
        return { ...state, loadingOUList: true };
      case "notLoadingUserList":
        return { ...state, loadingUserList: false };
      case "notLoadingOUList":
        return { ...state, loadingOUList: false };
      case "setOUID":
        return { ...state, ou_id: action.data };
      case "loadUserList":
        //console.log(action.data);
        let me = JSON.parse(sessionStorage.getItem("MyProfile"));
        let myAccessLevel = me.access_level;
        let userList = action.data.filter((data) => {
          return data.access_level > myAccessLevel;
        });
        return {
          ...state,
          userList: userList,
          loadingUserList: false,
        };
      case "newRisk":
        let riskList = action.newResults.results.data;
        riskList.forEach((risk, riskIndex) => {
          risk.owner.forEach((username, ownerIndex) => {
            let display_name = action.newResults.userList.filter((user) => {
              return user.username === username;
            })[0].display_name;
            riskList[riskIndex]["owner"][ownerIndex] = display_name;
          });
        });
        return {
          ...state,
          riskList: riskList,
          loadingRisk: false,
        };

      case "loadOUList":
        let ouList = action.data;
        ouList.map((OU) => {
          OU.sub_entity = ouList.filter((ou) => {
            return Object.keys(OU.children).indexOf(ou.ou_id) !== -1;
          });
          OU.revenue = OU.is_child ? subRevenue : parentRevenue;
        });

        return {
          ...state,
          OUList: ouList,
          loadingOUList: false,
        };
      case "OUCreationMsg":
        return {
          ...state,
          OUCreationMsg: action.data,
        };
      case "updatingOU":
        return {
          ...state,
          updatingOU: true,
        };
      case "creatingOU":
        return {
          ...state,
          creatingOU: true,
        };
      case "updatingShare":
        return {
          ...state,
          updatingShare: true,
        };
      case "updatingOUFinish":
        return {
          ...state,
          updatingOU: false,
        };
      case "creatingOUFinish":
        return {
          ...state,
          creatingOU: false,
        };
      case "updatingShareFinish":
        return {
          ...state,
          updatingShare: false,
        };
      default:
        throw Error(`Unknown action type '${action.type}'`);
    }
  }
}

export default App;
