import React from "react";
import { navigateToUrl } from "single-spa";
import { EntityRegistry } from "./component/entityRegistry/entityRegistry";
import { CreateEntityDetail } from "./component/createEntity/createEntityDetail";
import { ViewContentSwitchTab } from "./component/viewEntity/viewContentSwitchTab";
import { mapping, Loading, businessEntityStyle } from "@aim-mf/styleguide";
// this part i think can use AutoComplete from styleguide

// fakedata:

class Routing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.pageNav,
      OU: {},
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.pageNav);
    //console.log(this.props.pageNav);
    if (nextProps.pageNav !== this.props.pageNav) {
      this.setState({ page: nextProps.pageNav });
    }
  }

  render() {
    //console.log(this.props.userList);

    let page = this.state.page;
    let ouList = this.props.OUList;
    if (ouList.length > 0) {
      ouList.map((ou) => {
        ou.risks_count = this.props.RiskList.filter((risk) => {
          return risk.ou_id === ou.ou_id;
        }).length;
      });

      let current_ou_detail = this.props.OUList.filter((ou) => {
        return ou.ou_id === this.props.current_ou;
      })[0];

      let OURegisterList = [this.props.current_ou].concat(
        Object.keys(current_ou_detail.children)
      );
      ouList = ouList.filter((ou) => {
        return (
          OURegisterList.indexOf(ou.ou_id) !== -1 ||
          ou.ou_id === this.props.current_ou
        );
      });
      //console.log(ouList);
    }

    if (page === "/entity") {
      //return <EntityRegistry data={this.props.OUList} />;
      // need to manipulate the ou List data (count risks)
      return (
        <div>
          <div
            style={
              this.props.loading
                ? businessEntityStyle.BlurEffectStyle
                : { "": "" }
            }
          >
            <EntityRegistry
              access_level={this.props.access_level}
              handleEntityDetail={this.handleEntityDetail}
              // to be understand why it have to be a hard copy of the object to get the risks_count working in the map function in child component
              data={ouList}
              handleCreateEntityNav={this.handleCreateEntityNav}
            />
          </div>
          {this.props.loading && (
            <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
          )}
        </div>
      );
    } else if (page === "/entity/create") {
      return (
        <div>
          <div
            style={
              this.props.loading
                ? businessEntityStyle.BlurEffectStyle
                : { "": "" }
            }
          >
            <CreateEntityDetail
              defaultShareholding={this.props.defaultShareholding}
              OUList={this.props.OUList}
              userList={this.props.userList}
              handleCreateEntity={this.props.handleCreateEntity}
              OUCreationMsg={this.props.OUCreationMsg}
            />
          </div>
          {this.props.loading && (
            <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
          )}
        </div>
      );
    } else if (page.startsWith("/entity/view")) {
      let ou_id = page.split("/view/")[1];
      let OU = this.props.OUList.filter((ou) => ou.ou_id === ou_id)[0];
      return (
        <div>
          <div
            style={
              this.props.loading
                ? businessEntityStyle.BlurEffectStyle
                : { "": "" }
            }
          >
            <ViewContentSwitchTab
              defaultShareholding={this.props.defaultShareholding}
              totalAmount={this.props.totalAmount}
              OU={OU}
              OUList={this.props.OUList}
              RiskList={this.props.RiskList}
              userList={this.props.userList}
              handleEntityEdit={this.props.handleEntityEdit}
              handleOwnership={this.props.handleOwnership}
            />
          </div>
          {this.props.loading && (
            <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
          )}
        </div>
      );
    } else {
      return <div />;
    }
  }

  handleCreateEntityNav = () => {
    navigateToUrl("/entity/create");
  };

  handleEntityDetail = (e) => {
    navigateToUrl("/entity/view/" + e.ou_id);
    // TODO: add detail OU request to get OU detail
  };
}
export { Routing };
