import React from "react";
import { ButtonSolid, businessEntityStyle } from "@aim-mf/styleguide";

// can check how i did for blur effect in risk management, on file "risk Management"
class CreationCompletePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={businessEntityStyle.creationPageStyle}>
        <div style={businessEntityStyle.creationCompleteStyle}>
          <div style={businessEntityStyle.accountCreatedStyle}>
            {this.props.title}
          </div>
          <div style={businessEntityStyle.lineStyle} />
          <div style={businessEntityStyle.emailSentStyle}>
            {this.props.content}
          </div>
          <div style={businessEntityStyle.popupButtonPosition}>
            <ButtonSolid
              name={"Confirm"}
              height={"2.375rem"}
              width={"9.375rem"}
              color={"Primary"}
              clickEvent={this.props.callBack}
            />
          </div>
        </div>
      </div>
    );
  }
}
export { CreationCompletePopup };
