import React from "react";
// this part i think can use namecard from styleguide
import {
  AddUser,
  NameCard,
  mapping,
  ButtonSolid,
  NonValidationInput,
  DropDownListButton,
  CustomizedCheckBox,
  AutoSizeTextArea,
  businessEntityStyle,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";
import { CreationCompletePopup } from "../creationCompletePopup";

class CreateEntityDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_number: "",
      display_name: "",
      owner: [],
      description: "",
      is_child: false,
      parent_id: this.props.OUList[0].ou_id,
      AddUser: false,
    };
  }

  render() {
    //console.log(this.props.accessLevel[2]);
    // console.log(this.props.user);
    return (
      <div>
        <div
          style={{
            width: "31.75rem",
            margin: "auto",
            ...(this.state.AddUser || this.props.OUCreationMsg !== ""
              ? businessEntityStyle.BlurEffectStyle
              : { dummy: "dummy" }),
          }}
        >
          <div style={businessEntityStyle.searchDirectory}>
            <div style={businessEntityStyle.userInfoStyle}>
              Register New Entity
            </div>
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.sectionTitle}>ENTITY ID</div>
            <NonValidationInput
              defaultValue={this.state.id}
              width={"100%"}
              onChange={(event) => {
                this.setState({ entity_number: event.value });
              }}
            />
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.sectionTitle}>ENTITY NAME</div>
            <NonValidationInput
              defaultValue={this.state.display_name}
              width={"100%"}
              onChange={(event) => {
                this.setState({ display_name: event.value });
              }}
            />
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.sectionTitle}>
              ENTITY DESCRIPTION
            </div>
            <TextInput
              width={"100%"}
              minRows={5}
              value={this.state.description}
              onCustomChange={(e) => {
                this.setState({ description: e });
              }}
            />
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.formCheckBoxStyle}>
              <div style={businessEntityStyle.sectionTitle}>
                is a subsidiary entity
              </div>
              <CustomizedCheckBox
                checkBoxDivStyle={{ fontSize: 0 }}
                value={this.state.is_child}
                onCheckChange={() => {
                  this.setState({ is_child: !this.state.is_child });
                }}
              />
            </div>
            {this.state.is_child && (
              <div>
                <div style={businessEntityStyle.fieldPadding} />
                <div style={businessEntityStyle.sectionTitle}>
                  PARENT ENTITY
                </div>
                <DropDownListButton
                  data={this.props.OUList.map((a) => a.display_name)}
                  value={this.props.OUList.map((a) => a.display_name)[0]}
                  DropDownButtonStyle={
                    businessEntityStyle.titleDropDownButtonStyle
                  }
                  CustomOnChange={(e) => {
                    let selected_ou = this.props.OUList.filter((ou) => {
                      return ou.display_name === e.value;
                    })[0];
                    this.setState({ parent_id: selected_ou.ou_id });
                  }}
                />
              </div>
            )}
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.sectionTitle}>
              ENTITY ADMINISTRATOR
            </div>
            {this.state.owner.map((owner) => {
              // console.log(owner)
              return (
                <div style={businessEntityStyle.riskOwnerContentStyle}>
                  <NameCard
                    key={owner}
                    initial={owner.display_name
                      .split(" ")
                      .map((a) => {
                        return a[0].toUpperCase();
                      })
                      .join("")}
                    name={owner.display_name}
                    title={owner.title}
                  />
                </div>
              );
            })}
            {/*TODO: add namecard array here */}

            <div style={{ paddingTop: "1.25rem" }}>
              <ButtonSolid
                name={"Add\u00a0\u00a0+"}
                fontStyle={mapping["Font_Button_Badge_White"]}
                height={"1.125rem"}
                width={"3.25rem"}
                size={"Small"}
                clickEvent={() => {
                  this.setState({ AddUser: true });
                }}
              />
            </div>
          </div>
          <div style={businessEntityStyle.buttonPosition}>
            <ButtonSolid
              name={"Cancel"}
              color={"Secondary"}
              clickEvent={() => {
                navigateToUrl("/entity");
              }}
              height={"2.375rem"}
              width={"6.25rem"}
            />
            <div style={businessEntityStyle.rightButtonPosition}>
              <ButtonSolid
                name={"Register"}
                clickEvent={() => {
                  this.handleCreationSubmit();
                }}
                height={"2.375rem"}
                width={"6.25rem"}
              />
            </div>
          </div>
        </div>
        {this.state.AddUser && (
          <AddUser
            title={"Choose Entity Administrator"}
            userList={this.props.userList}
            currentUser={{ currentUser: this.state.owner }}
            handleAddUserClose={this.handleAddUserClose}
            handleAddUserSubmit={this.handleAddUserSubmit}
          />
        )}
        {this.props.OUCreationMsg !== "" && (
          <CreationCompletePopup
            title={"Business Entity Register Success"}
            content={this.props.OUCreationMsg}
            // callBack={console.log("hello")}
          />
        )}
      </div>
    );
  }

  handleAddUserClose = () => {
    this.setState({ AddUser: false });
  };

  handleAddUserSubmit = (e) => {
    this.setState({
      AddUser: false,
      owner: e,
    });
  };
  handleCreationSubmit = () => {
    let detail = {
      entity_number: this.state.entity_number,
      display_name: this.state.display_name,
      description: this.state.description,
      owner: this.state.owner.map((a) => a.username),
      is_child: this.state.is_child,
    };
    let ownership = {
      parent_ou_id: this.state.parent_id,
      ownership: this.props.defaultShareholding,
    };
    let dataPack = {
      detail: detail,
      ownership: ownership,
    };
    this.props.handleCreateEntity(dataPack);
  };
}

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={{
        ...businessEntityStyle.InputFieldStyle,
        width: props.width,
        ...props.customizedStyles,
      }}
      value={props.value}
      minRows={props.minRows}
      CustomizedOnChange={onChange}
    />
  );
};

export { CreateEntityDetail };
