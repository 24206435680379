import React from "react";
// this part i think can use namecard from styleguide
import {
  AddUser,
  mapping,
  ButtonSolid,
  NonValidationInput,
  DropDownListButton,
  CustomizedCheckBox,
  AutoSizeTextArea,
  NameCard,
  businessEntityStyle,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class Information extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.OU.ou_id,
      entity_number: this.props.OU.entity_number,
      display_name: this.props.OU.display_name,
      owner: this.props.OU.owner,
      description: this.props.OU.description,
      is_child: this.props.OU.is_child,
      parent_id: this.props.OU.parent_ou_id,
      AddUser: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (JSON.stringify(nextProps.OU) !== JSON.stringify(this.props.OU)) {
      this.setState({
        id: nextProps.OU.ou_id,
        entity_number: nextProps.OU.entity_number,
        display_name: nextProps.OU.display_name,
        owner: nextProps.OU.owner,
        description: nextProps.OU.description,
        is_child: nextProps.OU.is_child,
        parent_id: this.props.OU.parent_ou_id,
      });
    }
  }

  render() {
    // console.log(this.props.accessLevel[2]);
    // console.log(this.props);
    return (
      <div style={{ backgroundColor: mapping["Color_BG_05_solid"] }}>
        <div
          style={{
            ...businessEntityStyle.informationWrapper,
            ...(this.state.AddUser
              ? businessEntityStyle.BlurEffectStyle
              : { dummy: "dummy" }),
          }}
        >
          <div style={businessEntityStyle.informationContainer}>
            <div style={businessEntityStyle.sectionTitle}>ENTITY ID</div>
            <NonValidationInput
              defaultValue={this.state.entity_number}
              width={"100%"}
              onChange={(event) => {
                this.setState({ entity_number: event.value });
              }}
            />
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.sectionTitle}>ENTITY NAME</div>
            <NonValidationInput
              defaultValue={this.state.display_name}
              width={"100%"}
              onChange={(event) => {
                this.setState({ display_name: event.value });
              }}
            />
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.sectionTitle}>
              ENTITY DESCRIPTION
            </div>
            <TextInput
              width={"100%"}
              minRows={5}
              value={this.state.description}
              onCustomChange={(e) => {
                this.setState({ description: e });
              }}
            />
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.formCheckBoxStyle}>
              <div style={businessEntityStyle.sectionTitle}>
                is a subsidiary entity
              </div>
              <CustomizedCheckBox
                checkBoxDivStyle={{ fontSize: 0 }}
                value={this.state.is_child}
                onCheckChange={() => {
                  this.setState({ is_child: !this.state.is_child });
                }}
              />
            </div>
            {this.state.is_child && (
              <div>
                <div style={businessEntityStyle.fieldPadding} />
                <div style={businessEntityStyle.sectionTitle}>
                  PARENT ENTITY
                </div>
                {/*TODO: for now it only support only one parent entities*/}
                <DropDownListButton
                  data={this.props.OUList.map((a) => a.display_name)}
                  value={
                    this.state.parent_id[0]
                      ? this.props.OUList.filter((ou) => {
                          return ou.ou_id === this.state.parent_id[0];
                        })[0].display_name
                      : ""
                  }
                  DropDownButtonStyle={
                    businessEntityStyle.titleDropDownButtonStyle
                  }
                  CustomOnChange={(e) => {
                    let selected_ou = this.props.OUList.filter((ou) => {
                      return ou.display_name === e.value;
                    })[0];
                    this.setState({ parent_id: [selected_ou.ou_id] });
                  }}
                />
              </div>
            )}
            <div style={businessEntityStyle.fieldPadding} />
            <div style={businessEntityStyle.sectionTitle}>
              ENTITY ADMINISTRATOR
            </div>
            {this.state.owner.map((username) => {
              let owner = this.props.userList.filter(
                (user) => user.username === username
              )[0];
              if (!owner) return <div />;
              return (
                <div style={businessEntityStyle.riskOwnerContentStyle}>
                  <NameCard
                    key={owner}
                    initial={owner.display_name
                      .split(" ")
                      .map((a) => {
                        return a[0].toUpperCase();
                      })
                      .join("")}
                    name={owner.display_name}
                    title={owner.title}
                  />
                </div>
              );
            })}
            {/*TODO: add namecard array here */}

            <div style={{ paddingTop: "1.25rem" }}>
              <ButtonSolid
                name={"Add\u00a0\u00a0+"}
                fontStyle={mapping["Font_Button_Badge_White"]}
                height={"1.125rem"}
                width={"3.25rem"}
                size={"Small"}
                clickEvent={() => {
                  this.setState({ AddUser: true });
                }}
              />
            </div>
          </div>
          <div
            style={{
              ...businessEntityStyle.buttonPosition,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <ButtonSolid
              name={"Back"}
              color={"Secondary"}
              height={"2.375rem"}
              width={"6.25rem"}
              clickEvent={() => {
                navigateToUrl("/entity");
              }}
            />
            <div style={businessEntityStyle.rightButtonPosition}>
              <ButtonSolid
                name={"Save Changes"}
                height={"2.375rem"}
                width={"8.125rem"}
                clickEvent={() => {
                  this.handleEditSubmit();
                }}
              />
            </div>
          </div>
        </div>
        {this.state.AddUser && (
          <AddUser
            title={"Choose Entity Administrator"}
            userList={this.props.userList}
            currentUser={{
              currentUser: convertUserNameToUser(
                this.props.userList,
                this.state.owner
              ),
            }}
            handleAddUserClose={this.handleAddUserClose}
            handleAddUserSubmit={this.handleAddUserSubmit}
          />
        )}
      </div>
    );
  }

  handleAddUserClose = () => {
    this.setState({ AddUser: false });
  };

  handleAddUserSubmit = (e) => {
    this.setState({
      AddUser: false,
      owner: e.map((a) => {
        return a.username;
      }),
    });
  };
  handleEditSubmit = () => {
    let detail = {
      ou_id: this.state.id,
      entity_number: this.state.entity_number,
      display_name: this.state.display_name,
      description: this.state.description,
      owner: this.state.owner,
      is_child: this.state.is_child,
    };
    let PreOwnership = {
      parent_ou_id: this.props.OU.parent_ou_id,
      ownership: this.props.defaultShareholding,
    };
    let ownership = {
      parent_ou_id: this.state.parent_id,
      ownership: this.state.defaultShareholding,
    };
    let dataPack = {
      detail: detail,
      PreOwnership: PreOwnership,
      ownership: ownership,
    };
    //console.log(dataPack);
    this.props.handleEntityEdit(dataPack);
  };
}

const convertUserNameToUser = (userList, usernameList) => {
  return userList.filter((user) => usernameList.indexOf(user.username) !== -1);
};

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={{
        ...businessEntityStyle.InputFieldStyle,
        width: props.width,
        ...props.customizedStyles,
      }}
      value={props.value}
      minRows={props.minRows}
      CustomizedOnChange={onChange}
    />
  );
};

export { Information };
