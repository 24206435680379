import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy, process } from "@progress/kendo-data-query";
import {
  SearchBar,
  Pill,
  FormatDate,
  IconSVG,
  mapping,
  businessEntityStyle,
} from "@aim-mf/styleguide";
import { HeaderRender } from "../../HeaderRender";

class RiskRegistry extends React.Component {
  constructor(props) {
    super(props);
    let dataWithSelection = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    this.state = {
      sort: [{ field: "display_name", dir: "asc", data: this.props.data }],
      result: dataWithSelection,
      filterData: dataWithSelection,
      searchData: dataWithSelection,
      dataState: "",
      editUser: { display: false },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let dataWithSelection = nextProps.data.map((dataItem) =>
        Object.assign({ selected: false }, dataItem)
      );
      this.setState({
        sort: [{ field: "username", dir: "asc", data: nextProps.data }],
        result: dataWithSelection,
        filterData: dataWithSelection,
        searchData: dataWithSelection,
        dataState: "",
      });
    }
  }

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    // reformat result cater for the display requirement
    let displayResult = JSON.parse(JSON.stringify(this.state.result));
    displayResult = displayResult.map((a) => {
      let created_date = FormatDate(a.created_date, "MM/DD/YYYY HH:MM:SS");
      return {
        ...a,
        created_date: created_date,
      };
    });

    return (
      <div style={businessEntityStyle.moduleWrapper}>
        <div
          id="table-tool-bar"
          style={businessEntityStyle.toolBarWrapper}
          className="mb-1"
        >
          <SearchBar
            backgroundColor={mapping["Field_BG_Color"]}
            searchCallBack={this.onSearchCallBack}
            hint={"Search by risk name..."}
          />
        </div>
        <div style={businessEntityStyle.userTableWrapper}>
          <Grid
            style={businessEntityStyle.gridStyle}
            {...this.state.dataState}
            data={this.customOrderBy(displayResult, this.state.sort)}
            sortable
            onDataStateChange={this.dataStateChange}
            selectedField="selected"
            onSelectionChange={this.selectionChange}
            onHeaderSelectionChange={this.headerSelectionChange}
            onRowClick={this.rowClick}
            sort={this.state.sort}
            rowRender={this.rowRender}
            onSortChange={(e) => {
              let sortConfig = e.sort[0];
              if (!sortConfig) {
                this.setState({
                  sort: [{ data: this.props.data }],
                });
              } else {
                sortConfig = { ...sortConfig, data: this.props.data };
                this.setState({
                  sort: [sortConfig],
                });
              }
            }}
          >
            <Column
              field="selected"
              width={(50 / 16) * rem + "px"}
              headerSelectionValue={
                this.state.result.findIndex(
                  (dataItem) => dataItem.selected === false
                ) === -1
              }
              headerCell={HeaderRender}
            />
            <Column
              field="riskNumber"
              title="ID"
              width={(170 / 16) * rem + "px"}
              cell={(props) => {
                return (
                  <td className="table-risk-id">
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div>
                      {props.dataItem["parentRisk_id"] ? "⤷" : " "}
                      {props.dataItem[props.field]}
                    </div>
                  </td>
                );
              }}
              headerCell={HeaderRender}
            />
            <Column
              field="name"
              title="Entity Risk Name"
              width={(300 / 16) * rem + "px"}
              headerCell={HeaderRender}
            />
            <Column
              field="global_mapping_name"
              title="Global Risk Mapping"
              width={(300 / 16) * rem + "px"}
              headerCell={HeaderRender}
            />
            <Column
              field="category"
              title="Category"
              headerCell={HeaderRender}
            />
            <Column field="tier" title="Tier" headerCell={HeaderRender} />

            <Column
              field="created_date"
              title="Date Created"
              width={(200 / 16) * rem + "px"}
              headerCell={HeaderRender}
            />
            <Column
              field="owner"
              title="Administrator"
              cell={(props) => {
                if (props.dataItem[props.field]) {
                  return (
                    <td>
                      {props.dataItem[props.field].map((owner) => {
                        return (
                          <Pill
                            pillStyle={{
                              display: "inline-flex",
                              marginRight: "0.3rem",
                            }}
                            content={owner}
                            color={"rgba(124,199,255,0.2)"}
                          />
                        );
                      })}
                    </td>
                  );
                } else {
                  return <td />;
                }
              }}
              headerCell={HeaderRender}
            />
            <Column
              title="Edit"
              width={(60 / 16) * rem + "px"}
              cell={(props) => {
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <td
                    onClick={() => {
                      this.handleEditUser(props.dataItem);
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        placeContent: "center",
                        width: "100%",
                      }}
                    >
                      <IconSVG
                        name={"edit"}
                        color={mapping["Color_Basic_Primary"]}
                        size={0.8}
                      />
                    </div>
                  </td>
                );
              }}
              headerCell={HeaderRender}
            />
            <Column
              title="Delete"
              width={(70 / 16) * rem + "px"}
              cell={(props) => {
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <td
                    onClick={() => {
                      let payload = JSON.parse(JSON.stringify(props.dataItem));
                      payload.is_active = false;
                      delete payload.email;
                      this.props.handleUpdateUser(payload, [], []);
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        placeContent: "center",
                        width: "100%",
                      }}
                    >
                      <IconSVG
                        name={"delete"}
                        color={mapping["Color_Basic_Primary"]}
                        size={0.8}
                      />
                    </div>
                  </td>
                );
              }}
              headerCell={HeaderRender}
            />
          </Grid>
          <div style={{height:"2.5rem"}}/>
        </div>
      </div>
    );
  }

  selectionChange = (event) => {
    const result = this.state.result.map((item) => {
      if (item.username === event.dataItem.username) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    this.setState({ result });
  };
  rowClick = (event) => {
    //console.log(this.props.userList);
    let last = this.lastSelectedIndex;
    const result = [...this.state.result];
    const current = result.findIndex((dataItem) => dataItem === event.dataItem);
    result.forEach((item) => (item.selected = false));
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      result[i].selected = select;
    }
    this.setState({ result });
  };

  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const result = this.state.result.map((item) => {
      item.selected = checked;
      return item;
    });
    this.setState({ result });
  };

  // search related
  onSearchCallBack = (e) => {
    let searchContent = e.target.value;

    let resultData = this.state.filterData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let searchData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );

    if (!searchContent) {
      this.setState({ result: resultData, searchData: searchData });
      return;
    }
    let userOnSearch = this.state.filterData.filter((userItem) => {
      if (userItem.name) {
        return userItem.name
          .toLowerCase()
          .includes(searchContent.toLowerCase());
      }
      if (userItem.global_mapping_name) {
        return userItem.global_mapping_name
          .toLowerCase()
          .includes(searchContent.toLowerCase());
      }
    });
    this.setState({ searchData: userOnSearch, result: userOnSearch });
  };

  // filter related
  createDataState = (dataState) => {
    let resultData = this.state.searchData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let filterData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    return {
      result: process(resultData, dataState).data,
      filterData: process(filterData, dataState).data,
      dataState: dataState,
    };
  };

  dataStateChange = (event) => {
    var datastate = event.data;
    datastate.take = 100;
    this.setState(this.createDataState(datastate));
  };

  // sorting function
  customOrderBy = (data, sort) => {
    let result = [];

    let sortedData = orderBy(data, sort);

    while (sortedData.length !== 0) {
      //console.log(sortedData);
      if (sortedData[0].isParentRisk) {
        let parentID = sortedData[0].sourceRiskID;
        result = result.concat(sortedData[0]);
        sortedData = sortedData.filter((risk) => {
          return risk.sourceRiskID != parentID;
        });
        let subRisk = sortedData.filter((risk) => {
          return risk.parentRisk_id == parentID;
        });
        result = result.concat(subRisk);
        sortedData = sortedData.filter((risk) => {
          return risk.parentRisk_id != parentID;
        });
      } else {
        let parentID = sortedData[0].parentRisk_id;
        let parentRiskSort = sortedData.filter((risk) => {
          return risk.sourceRiskID == parentID;
        });
        result = result.concat(parentRiskSort);
        sortedData = sortedData.filter((risk) => {
          return risk.sourceRiskID != parentID;
        });
        let subRisk = sortedData.filter((risk) => {
          return risk.parentRisk_id === parentID;
        });
        result = result.concat(subRisk);
        sortedData = sortedData.filter((risk) => {
          return risk.parentRisk_id !== parentID;
        });
      }

      //console.log(sortedSubRisk);
      //console.log(result);
    }

    //console.log("sort function");

    //console.log(result);

    return result;
  };

  rowRender(trElement, props) {
    const parentRow = { backgroundColor: "#00000066" };
    const subRiskRow = { backgroundColor: "#00000000" };
    const trProps = {
      style: props.dataItem.isParentRisk ? parentRow : subRiskRow,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }
}

export { RiskRegistry };
