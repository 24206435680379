import React from "react";
import { NonValidationInput, businessEntityStyle } from "@aim-mf/styleguide";

class FinancialGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OUList: "",
    };
  }

  render() {
    return (
      <div>
        <div style={businessEntityStyle.sectionTitleStyle}>Weightage Value</div>
        <div style={businessEntityStyle.checkBoxTitleStyle}>
          {this.props.name}
        </div>
        <NonValidationInput
          defaultValue={"S$" + this.props.revenue}
          width={this.props.width + "rem"}
          onChange={(event) => {
            this.setState({ id: event.value });
          }}
          disabled={true}
        />
      </div>
    );
  }
}

export { FinancialGoal };
