import React from "react";
import {
  mapping,
  ButtonSolid,
  DropDownListButton,
  businessEntityStyle,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";
import { FinancialGoal } from "./financial/financialGoal";
import { FinancialEntities } from "./financial/financialEntities";

const valueWidth = 13.75;
const TypeList = ["Revenue", "Head Count"];
class AggregationSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.OU.children,
    };
  }

  render() {
    // console.log(this.state);
    let ParentWidth = Math.max(this.props.OU.sub_entity.length * 15, 31.25);
    return (
      <div style={{ backgroundColor: mapping["Color_BG_05_solid"] }}>
        <div
          style={{
            width: ParentWidth + "rem",
            margin: "0.9375rem",
            marginTop: 0,
            marginLeft: "6.25rem",
            paddingTop: "1.5625rem",
          }}
        >
          <div style={businessEntityStyle.informationContainer}>
            <div style={businessEntityStyle.sectionTitleStyle}>
              Calculate Weight Based on
            </div>
            <DropDownListButton
              data={TypeList}
              value={TypeList[0]}
              DropDownButtonStyle={{
                ...businessEntityStyle.titleDropDownButtonStyle,
                width: "31.25rem",
              }}
              CustomOnChange={(e) => {
                let selected_ou = this.props.OUList.filter((ou) => {
                  return ou.display_name === e.value;
                })[0];
                this.setState({ parent_entity: selected_ou });
              }}
            />

            <div style={businessEntityStyle.fieldPadding} />
            <FinancialGoal
              name={this.props.OU.display_name}
              revenue={this.props.OU.revenue}
              width={valueWidth}
              totalAmount={this.props.totalAmount}
            />
            <div style={{ display: "inline-flex" }}>
              {this.props.OU.sub_entity.map((ou, ou_index) => {
                if (ou_index === 0) {
                  return <VerticalLine key={ou_index} />;
                }
                return <HorizontalLine key={ou_index} />;
              })}
            </div>
            <div style={businessEntityStyle.fieldPadding} />
            <div style={{ display: "inline-flex" }}>
              {this.props.OU.sub_entity.map((ou) => {
                return (
                  <FinancialEntities
                    key={ou.ou_id}
                    id={ou.ou_id}
                    name={ou.display_name}
                    parentRevenue={this.props.OU.revenue}
                    revenue={ou.revenue}
                    width={valueWidth}
                    defaultShareholding={this.props.OU.children[ou.ou_id] * 100}
                    suggestedShareholding={10}
                    updateShareholding={this.updateShareholding}
                  />
                );
              })}
            </div>
            <div
              style={{
                ...businessEntityStyle.buttonPosition,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <ButtonSolid
                name={"Cancel"}
                color={"Secondary"}
                height={"2.375rem"}
                width={"6.25rem"}
                clickEvent={() => {
                  navigateToUrl("/entity");
                }}
              />
              <ButtonSolid
                name={"Save Changes"}
                height={"2.375rem"}
                width={"8.125rem"}
                color={"Primary"}
                clickEvent={() => {
                  this.handleShareholdingUpdate();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  updateShareholding = (ou_id, shareholding) => {
    this.setState({ [ou_id]: shareholding / 100.0 });
  };
  handleShareholdingUpdate = () => {
    let childrenList = this.state;
    for (let key in childrenList) {
      if (childrenList.hasOwnProperty(key)) {
        this.props.handleOwnership({
          child_ou_id: key,
          parent_ou_id: this.props.OU.ou_id,
          ownership: childrenList[key],
        });
      }
    }
  };
}

const VerticalLine = () => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        marginLeft: valueWidth / 2 + "rem",
        marginRight: 0,
        marginTop: "0.625rem",
        height: "6.25rem",
        width: 0,
        border: "#00BEFF solid 0.5px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-5px",
          left: "-5px",
          height: "0px",
          width: "0px",
          borderRadius: "100%",
          border: "#00BEFF solid 5px",
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "-5px",
          left: "-5px",
          height: "0px",
          width: "0px",
          borderRadius: "100%",
          border: "#00BEFF solid 5px",
        }}
      />
    </div>
  );
};

const HorizontalLine = () => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        margin: "auto",
        marginBottom: "0",
        height: "33px",
        width: valueWidth + 1.25 + "rem",
        border: "#00BEFF solid 0.5px",
        borderWidth: "1px 1px 0 0",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "-5px",
          right: "-5px",
          height: "0px",
          width: "0px",
          borderRadius: "100%",
          border: "#00BEFF solid 5px",
        }}
      />
    </div>
  );
};

export { AggregationSetting };
