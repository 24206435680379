import React from "react";
import {
  mapping,
  NonValidationInput,
  businessEntityStyle,
} from "@aim-mf/styleguide";

class FinancialEntities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userShareholding: this.props.defaultShareholding,
      entityAmount: (
        (this.props.parentRevenue * this.props.defaultShareholding) /
        100
      ).toFixed(2),
    };
  }

  render() {
    //console.log(this.state.entityAmount)
    return (
      <div style={{ marginRight: "1.25rem" }}>
        <NonValidationInput
          value={"S$" + this.state.entityAmount}
          width={this.props.width + "rem"}
          onChange={(event) => {
            this.setState({ id: event.value });
          }}
          disabled={true}
        />
        <div style={{ height: "1.5625rem" }} />
        <div style={businessEntityStyle.checkBoxTitleStyle}>
          {this.props.name}
        </div>
        <div
          style={{
            backgroundColor: mapping["Field_BG_Color"],
            width: this.props.width + "rem",
            padding: "1.25rem",
          }}
        >
          <div
            style={{
              ...businessEntityStyle.sectionTitleStyle,
              paddingTop: "0",
            }}
          >
            ACTUAL WEIGHTING
          </div>
          <div style={{ position: "relative" }}>
            <NonValidationInput
              value={this.state.userShareholding}
              width={this.props.width - 2.5 + "rem"}
              onChange={(event) => {
                this.setState({
                  userShareholding: Math.min(event.value, 100),
                  entityAmount: (
                    (this.props.parentRevenue * Math.min(event.value, 100)) /
                    100
                  ).toFixed(2),
                });
                this.props.updateShareholding(this.props.id, event.value);
              }}
              type={"number"}
            />
            <span style={businessEntityStyle.ThresholdUnitStyle}> {"%"} </span>
          </div>
          <div style={{ height: "1rem" }} />
          <div style={businessEntityStyle.sectionTitleStyle}>
            SUGGESTED WEIGHTING
          </div>
          <div style={{ position: "relative" }}>
            <NonValidationInput
              defaultValue={(
                (this.props.revenue / this.props.parentRevenue) *
                100
              ).toFixed(2)}
              width={this.props.width - 2.5 + "rem"}
              disabled={true}
            />
            <span style={businessEntityStyle.ThresholdUnitStyle}> {"%"} </span>
          </div>
        </div>
      </div>
    );
  }
}
export { FinancialEntities };
